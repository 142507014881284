.HelpPage {
  min-height: 98.4vh;
  display: flex;
  flex-direction: column;
}

.HelpMain {
  display: flex;
  flex-direction: column;
}

.helpContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
}

.helpImage {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  .HelpPage {
    flex-direction: row;
  }

  .helpContent {
    padding-left: 48px;
    padding-right: 48px;
  }

  .HelpMain {
    width: 81%;
  }
}