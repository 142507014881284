.EditarContaPage {
  display: flex;
  flex-direction: column;
  min-height: 98.4vh;
}

.editarContaMain {
  display: flex;
  flex-direction: column;
}

.editarContaContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
}

.editarContaContent > div {
  width: 100%;
}

.orangeText {
  color: #FAA123
}

.userDataAndChangeDataBtn {
  transition: 0.3s;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.changeDataBtnAndText {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.changeDataBtnAndText > img {
  margin-right: 12px;
}

.sucessBtn {
  padding: 10px 40px;
  background-color: #E4F5E5;
  color: #009C06; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.editPasswordDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editPasswordDiv > label {
  width: 100%;
  font-size: 16px;
}

.editPasswordDiv > label p {
  margin: 0;
  font-size: 16px;
}

.editPasswordDiv h3 {
  margin-top: 32px;
  margin-bottom: 20px;
}

#newPasswordInput {
  margin-top: 32px;
}

.forgotPasswordText {
  font-size: 12px;
}

@media (min-width: 1024px) {
  .EditarContaPage {
    flex-direction: row;
  }

  .forgotPasswordText {
    font-size: 14px;
  }

  #newPasswordInput {
    margin-top: 48px;
  }

  .editPasswordDiv h3 {
    margin-top: 48px;
    margin-bottom: 24px;
  }

  .userDataAndChangeDataBtn {
    margin-top: 28px;
  }

  .editarContaContent {
    flex-direction: row;
    padding-left: 48px;
    padding-right: 48px;
  }

  .editarContaContent > div {
    width: 48%;
  }

  .editarContaMain {
    width: 81%;
  }
}