.progressText {
  font-size: 14px;
  margin-bottom: 16px;
  margin-top: 34px;
}

.progressBarAndPercentage {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.progressBarAndPercentage img {
  width: 90%;
}

.progressBarAndPercentage p {
  font-size: 16px;
  margin-left: 12px;
  margin-bottom: 0;
}

@media (min-width: 1024px) {

}
