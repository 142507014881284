.SidebarComponent {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

#galeria {
  width: 0;
  height: 0;
  /* display: flex; */
  display: none;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  transition: 0.4s;
  overflow: hidden;
}

/* #galeria > p {
  margin: 0;
  margin-left: 15px;
  font-size: 18px;
  line-height: 20px;
  color: #B6B6B6;
}

#galeria img {
  margin-left: 26px;
  width: 18px;
  height: 18px;
  overflow: hidden;
  opacity: 0.3;
}

#galeria > .soonerDecorationText {
  font-size: 13px;
  line-height: 17px;
  background-color: #23A7FA;
} */

.soonerDecorationText {
  font-size: 13px;
  line-height: 17px;
  padding: 1px 10px;
  color: #FFFFFF;
  background-color: #23A7FA;
  border-radius: 5px;
}


.SidebarComponent > .sidebarHeader {
  display: none;
}

.SidebarComponent > .MobileNav {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.25);
  padding-top: 20px;
  padding-bottom: 20px;
}

.SidebarComponent > .MobileNav img {
  object-fit: cover;
  max-width: 31px;
  height: auto;
  cursor: pointer;
  margin-left: 24px;
  margin-right: 24px;
}

.MobileNav > .showOnMenuClick {
  display: none;
  margin: 0;
}

.SidebarComponent > .sidebarItem {
  width: 0;
  height: 0;
  /* display: flex; */
  display: none;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 19px;
  transition: 0.4s;
  overflow: hidden;
}

.SidebarComponent > .sidebarItem:hover {
  background-color: #FAA123;
}

.SidebarComponent > .sidebarItem p {
  margin: 0;
  margin-left: 15px;
  font-size: 18px;
  line-height: 20px;
}

.SidebarComponent > .sidebarItem img {
  margin-left: 26px;
  width: 18px;
  height: 18px;
  overflow: hidden;
}

.SidebarComponent > .sidebarItem svg {
  margin-left: 26px;
  width: 18px;
  height: 18px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .SidebarComponent {
    width: 19%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }

  .SidebarComponent > .sidebarHeader {
    cursor: pointer;
    display: flex;
    width: calc(100% - 15px);
    align-items: center;
    margin-left: 15px;
    padding-top: 24px;
    padding-bottom: 60px;
  }

  .SidebarComponent > .sidebarHeader img {
    margin-left: 16px;
  }

  .SidebarComponent > .MobileNav {
    display: none;
  }

  #galeria {
    width: auto;
    height: auto;
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #galeria > img {
    margin-left: 37px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }

  #galeria p {
    font-size: 20px;
    line-height: 25px;
  }

  .SidebarComponent > .sidebarItem {
    width: auto;
    height: auto;
    cursor: pointer;
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .SidebarComponent > .sidebarItem p {
    font-size: 20px;
    line-height: 25px;
  }
  
  .SidebarComponent > .sidebarItem img {
    margin-left: 37px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }

  .SidebarComponent > .sidebarItem svg {
    margin-left: 37px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }
}