.BlogPage {
  display: flex;
  flex-direction: column;
  min-height: 98.4vh;
}

.BlogComponent > .blogContent {
  padding: 40px 28px;
}

.secondBlogDiv > .allPostsDiv {
  margin-bottom: 0px;
  overflow: scroll;
  max-height: 82vh;
}

.smallerPostDiv {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.smallerText {
  font-size: 14px;
  line-height: 18px;
}

.draftsDiv {
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  padding: 28px 0px;
  margin-bottom: 40px;
}

.draftTitle {
  display: flex;
  justify-content: space-between;
  transition: 0.5s;
  padding: 11px 28px;
  cursor: pointer;
}

.draftTitle > h3 {
  margin: 0;
}

.draftTitle:hover {
  background-color: #FCD091;
}

.evenSmallerText {
  font-size: 12px;
  line-height: 18px;
}

.orangeBackgroundText {
  background-color: #FCD091;
  padding: 8px 29px 8px 16px;
  font-weight: 500;
}

.smallerPostHeader {
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.smallerPostHeader > .postHeaderAndIcon {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-right: 40px;
}

.postHeaderAndIcon > p {
  font-size: 12px;
  margin: 0;
  margin-left: 8px;
  line-height: 12px;
}

.smallerPostDiv > p {
  word-wrap: break-word;
}

.seePostText {
  margin-right: 10px;
  align-self: flex-end;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.blogContent > .secondBlogDiv {
  margin-top: 40px;
}

@media (min-width: 1024px) {
  .BlogPage {
    display: flex;
    flex-direction: row;
  }

  .blogContent > .mainBlogDiv {
    width: 70%;
  }

  .blogContent > .secondBlogDiv {
    margin-top: 0;
    margin-left: 40px;
    width: 30%;
  }

  .BlogComponent > .blogContent {
    padding: 40px 48px;
    display: flex;
    flex-direction: row;
  }
  
  .BlogPage > .BlogComponent {
    width: 81%;
  }
}