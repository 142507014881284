.GaleriaPage {
  min-height: 98.4vh;
  display: flex;
  flex-direction: column;
}

.galeriaMain {
  display: flex;
  flex-direction: column;
}

.galeriaContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
}

.galeriaContent > div {
  width: 100%;
}

.galeriaMain > h3 {
  font-size: 16px;
  text-align: center;
}

.imagesAndButtons {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mainArrayImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 55px;
  width: 100%;
  height: 192px;
}

.switchImagesBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.imageOptionsBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.imageOptionsBtn > img {
  width: 13px;
  height: auto;
}

.switchImagesBtn > img {
  width: 10px;
  height: auto;
}

.restOfImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.restOfImages > div {
  width: 30%;
  height: 100px;
}

.bottomImageDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modalDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modalImage {
  width: 100%;
}

.modalImage > img {
  max-height: 98.4vh;
  width: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.imageComponent {
  width: 100%;
  height: auto
}

.smallerImage {
  height: 100%;
  width: auto;
}

.smallerImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.smallerImageContainer > img {
  max-width: 100%;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .GaleriaPage {
    flex-direction: row;
  }

  .galeriaMain > h3 {
    margin-left: 48px;
    font-size: 24px;
    text-align: start;
  }

  .galeriaContent {
    padding-left: 48px;
    padding-right: 48px;
  }

  .galeriaContent {
    flex-direction: row;
  }

  .galeriaMain {
    width: 81%;
  }

  .modalImage > img {
    width: 50%;
    height: auto;
  }

  .switchImagesBtn {
    font-size: 24px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
  }

  .imageOptionsBtn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  .imageOptionsBtn > img {
    width: 15px;
    height: auto;
  }

  .switchImagesBtn > img {
    width: 24px;
    height: auto;
  }

  .mainArrayImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 55px;
    width: 100%;
    height: 325px;
  }
  
  #firstGalleryDiv {
    width: 120%;
  }

  #modalId {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
  }

  .restOfImages > div {
    max-width: 30%;
    height: 170px;
  }
}