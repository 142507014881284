.PagamentosPage {
  display: flex;
  flex-direction: column;
  min-height: 98.4vh;
}

.pagamentosMain {
  width: 100%;
}

.pagamentosContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
}

.lateralBorderAndText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lateralBorderAndText > div {
  border-radius: 4px;
  height: 20px;
  width: 6px;
}

.lateralBorderAndText > h4 {
  margin: 0;
  margin-left: 12px;
}

.valueAndRevealValueBtn {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.valueAndRevealValueBtn > p {
  margin: 0;
  margin-left: 19px;
}

.billPriceAndValidation {
  margin-top: 24px;
  margin-bottom: 32px;
  justify-content: space-between;
  display: flex;
  flex-direction: column-reverse;
}

.billPriceAndValidation > p {
  margin: 0;
  margin-bottom: 24px;
}

.twoButtonsPaymentDiv {
  display: block;
}

.twoButtonsPaymentDiv > button {
  margin-bottom: 16px;
}

.paymentTextAndImage {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paymentTextAndImage > p {
  margin: 0;
  margin-left: 12px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.paymentTextAndImage > img {
  cursor: pointer;
  max-width: 14px;
  max-height: auto;
}

.marginBottom {
  margin-bottom: 16px;
}

.smallIcon {
  display: none;
  height: 20px;
  font-size: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.fixSmallIcon {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.billText:empty::before {
  content: "";
  display: inline-block;
}

.billText {
  font-size: 14px;
}


.billValueText {
  margin-left: 16px;
  font-weight: 500;
  font-size: 24px;
}

.allBillsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.billData {
  width: 16.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.billGraph {
  margin-bottom: 24px;
  width: 8px;
  height: 120px;
  border-radius: 8px;
}

.soonerBtn {
  background-color: #FFFFFF;
  color: #B6B6B6;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0;
  transition: 0.5s;
}

.soonerBtnText {
  font-size: 14px;
  margin: 1px 20px 15px 20px;
}

.soonerBtn > .alignToBtnEndText {
  margin: 0;
  align-self: flex-end;
}

.extractHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.extractHeader > div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.extractHeader > div img {
  width: 100%;
  height: auto;
}

.extractHeader > div p {
  font-size: 14px;
  color: #FAA123;
  margin: 0;
  margin-left: 4px;
}

.extractBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tableHeader {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tableSide {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.tableSide > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tableBody {
  margin-top: 1rem;
  border-bottom: 1px solid #FAA123;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tableMain:last-child {
  border-bottom: none;
}

.installmentMonth {
  margin-left: 0;
}

.extractInputDiv {
  padding-top: 17px;
  padding-bottom: 17px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  max-width: 50%;
}

.extractInputDiv > img {
  margin-left: 20px;
}

.extractInputDiv > input {
  font-size: 14px;
  margin-left: 12px;
  border: none;
  width: 100%;
}

.extractInputDiv > input:focus {
  outline: none;
  border: none;
}

@media (min-width: 1024px) {
  .PagamentosPage {
    flex-direction: row;
  }

  .billValueText {
    font-size: 32px;
  }

  .fixSmallIcon {
    margin-top: 32px;
    flex-direction: row;
  }

  .paymentTextAndImage > p {
    font-size: 18px;
  }

  .smallIcon {
    display: block;
    height: 20px;
    font-size: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .marginBottom {
    margin-bottom: 24px;
  }

  .paymentTextAndImage > img {
    max-width: 20px;
    max-height: auto;
  }

  .twoButtonsPaymentDiv {
    display: flex;
    flex-direction: row;
  }

  .twoButtonsPaymentDiv > button {
    margin-right: 32px;
    margin-bottom: 0;
  }

  .billPriceAndValidation {
    margin-top: 32px;
    align-items: center;
    flex-direction: row;
  }

  .billPriceAndValidation > p {
    margin: 0;
    margin-bottom: 0;
  }

  .lateralBorderAndText > div {
    border-radius: 4px;
    height: 24px;
    width: 6px;
  }

  .pagamentosContent {
    flex-direction: row;
    padding-left: 48px;
    padding-right: 48px;
  }

  .pagamentosContent:first-child {
    margin-right: 40px;
  }

  .pagamentosContent > div {
    width: 49%;
  }

  .pagamentosMain {
    width: 81%;
  }

  .billText {
    font-size: 16px;
  }
  
  .billGraph {
    width: 12px;
    height: 140px;
    border-radius: 8px;
  }

  .tableSide {
    width: 30%;
  }

  .installmentMonth {
    margin-left: 55px;
  }
}

.strongRed {
  background-color: #D00101;
}

.strongOrange {
  background-color: #FAA123;
}

.strongGreen {
  background-color: #009C06;
}

.fadedOrange {
  background-color: #FCD091;
}