.CriarUsuarioPage {
  display: flex;
  flex-direction: column;
  min-height: 98.4vh;
}

.CriarUsuarioComponent {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 98.4vh;
  align-items: flex-start;
}

.CriarUsuarioComponent > .criarUsuarioMain {
  margin: 32px 48px;
  height: 100%;
  width: calc(100% - 96px);
}

.alignItensCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alignItensCenter > .genericBtn {
  margin-top: 20px;
}

.ClientDataInput {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.ClientDataInput > button {
  margin-top: 40px;
  align-self: center;
}

.suportIframe {
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) {

  .alignItensCenter {
    align-items: flex-start;
  }

  .CriarUsuarioPage {
    display: flex;
    flex-direction: row;
  }

  .CriarUsuarioComponent {
    display: flex;
    width: 81%;
    align-items: flex-start;
  }
}