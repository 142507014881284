.AtendimentoPage {
  display: flex;
  flex-direction: column;
  min-height: 98.4vh;
}

.atendimentoMain {
  width: 100%;
}

.biggerText {
  font-size: 20px;
}

.atendimentoContent {
  margin: 32px 48px;
  width: calc(100% -96px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.atendimentoContent > div {
  width: 90%;
}

.atnSecondDiv > ul li{
  margin-top: 24px;
}

.atendimentoMain > p {
  margin-top: 8px;
  text-align: center;
}

.atendimentoContent > div h3 {
  margin-bottom: 40px;
}

.atnBtn {
  margin-bottom: 16px;
  width: 50%;
}

.atnBtn img {
  max-width: 14px;
  margin-right: 14px;
  height: auto;
}

.atnBtn > p {
  margin: 0;
  font-size: 14px;
}

.atnDiv {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .AtendimentoPage {
    flex-direction: row;
  }

  .atnBtn img {
    max-width: 24px;
    height: auto;
  }

  .atnBtn > p {
    font-size: 18px;
  }

  .biggerText {
    font-size: 24px;
  }

  .atendimentoMain {
    width: 81%;
  }

  .atnSecondDiv {
    margin-left: 60px;
  }

  .atendimentoMain > p {
    text-align: start;
    margin-left: 48px;
    margin-top: 63px;
  }

  .atendimentoContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}