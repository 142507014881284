.EditPostComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.editPostBody {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.iconAndInput {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editPostBody > div {
  width: 100%;
}

.editPostBody > img {
  margin-top: 32px;
  margin-bottom: 28px;
  max-width: 80%;
}

.editPostBody > textarea {
  margin-bottom: 60px;
  height: 450px;
}

.editPostBody > .iconAndInput input {
  width: 100%;
}

.editReadTimeAndAuthor {
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.editPostBody > button {
  justify-content: center;
  text-align: center;
  width: 30%;
}

.EditPostComponent > div {
  width: 100%;
}

.EditPostComponent input {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 1px;
  margin-left: 16px;
}

.editReadTimeAndAuthor > .iconAndInput input {
  margin-right: 45px;
}

.editReadTimeAndAuthor > input {
  margin-right: 48px
}

.editPostBody > input {
  width: 100%;
}