.LoginComponent {
  min-height: 98.4vh;
  display: flex;
  flex-direction: row;
  background-color: #000;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.LoginComponent > .LogoAndButtonComponent {
  height: 98.4vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  transition: 0.5s;
}

.LoginComponent > .LogoAndButtonComponent img {
  width: 95%;
  height: auto;
}

.LoginComponent > .LoginForm {
  display: none;
  min-height: 98.4vh;
  width: 100%;
  background-color: #FFF;
  flex-direction: column;
  transition: 0.5s;
}

.LoginForm > .LogoAndLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.LoginForm > .LogoAndLabel img {
  margin-left: 24px;
}

.LoginForm > .credentialsInput {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginForm > .credentialsInput h2 {
  align-self: flex-start;
  margin-left: 5%;
  font-size: 36px;
  font-weight: 500;
}

.LoginForm > .credentialsInput h3 {
  align-self: flex-start;
  margin-left: 5%;
  font-size: 24px;
  font-weight: 500;
}

#startBtnMobile {
  font-size: 20px;
  padding: 10px 10px 10px 25px;
  border-radius: 15px 25px 25px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
}

#startBtnMobile:hover {
  background-color: #FFF;
}

#startBtnMobile > img {
  max-width: 26px;
  margin-left: 14px;
}

.actualInput > .inputLabel {
  font-size: 16px;
}

.ClientDataInput > .standardInput {
  margin-top: 0;
}

.clientInputLabel {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-top: 32px;
  margin-bottom: 12px;
}

.actualInput > .inputLabel > input {
  padding: 10px 25px 10px 25px;
  width: 100%;
  margin-top: 14px;
  border: 2px solid #FAA123;
  border-radius: 10px;
}

.LoginBtn {
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 45px 10px 45px;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  font-size: 20px;
}

.LoginBtn:hover {
  background-color: #000;
  color: #FAA123
}

#passwordForgot {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

@media (min-width: 1024px) {
  #startBtnMobile {
    display: none;
  }

  .LoginForm > .LogoAndLabel {
    display: none;
  }


  .LoginForm > .credentialsInput h2 {
    margin-left: 0;
  }
  
  .LoginForm > .credentialsInput h3 {
    margin-left: 0;
  }

  .LoginForm > .credentialsInput {
    display: block;
    margin-left: 15%;
    margin-right: 15%;
  }

  .actualInput > .inputLabel {
    font-size: 20px;
  }

  .LoginComponent > .LoginForm {
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 15px;
    min-height: 50vh;
    background-color: #FFF;
    width: 109%;
    margin-right: 1%;
    display: flex;
    flex-direction: column;
  }

  .actualInput > .inputLabel > input {
    width: 100%;
  }

  .LoginComponent > .LogoAndButtonComponent img {
    width: auto;
    height: auto;
  }

  #passwordForgot {
    font-size: 14px;
  }
}