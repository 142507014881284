.homeHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.homeHeader h2 {
  margin: 0;
  margin-top: 40px;
  font-size: 26px;
  line-height: 31px;
}

.homeHeader > .headerSideContent {
  display: none;
}

.headerSideContent > img {
  max-height: 16px;
  width: auto;
  cursor: pointer;
}

.npsDiv {
  width: 80%;
  border-radius: 8px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FCD091;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}

.npsMain {
  width: 100%;
  padding: 0 41px 28px 24px;
}

.npsMain > h2, .npsForm h2 { 
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 0 !important;
}

#closeNps {
  margin: 0;
  cursor: pointer;
  align-self: flex-end;
}

.npsNumeralsContainer {
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.npsNumeral {
  width: 24px;
  height: 24px;
  background-color: #FFFFFF;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

#npsScoreRating {
  margin-top: 12px;
  height: 17px;
  font-size: 12px;
  font-weight: 600;
}

#redNumeral {
  background-color: #FFEBEB;
  border: 1px solid #D00101;
  color: #D00101;
}

#greyNumeral {
  background-color: #999999;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}

#greenNumeral {
  background-color: #E4F5E5;
  border: 1px solid #009C06;
  color: #009C06;
}

.chosenNumerals {
  background-color: #FAA123;
  color: #000000;
  border: 1px solid #000000;
}

.npsForm {
  margin-top: 24px;
}

.npsForm > textarea {
  width: 100%;
  height: 165px;
  margin-top: 16px;
  border-radius: 10px;
  border: 2px solid #FAA123;
  padding: 16px;
  font-size: 12px;
  line-height: 15px;
  resize: none;
}

.npsForm > textarea::placeholder {
  font-size: 12px;
  line-height: 15px;
}

.npsForm > textarea:focus {
  outline: none;
  border: 2px solid #FAA123;
  border-radius: 8px;
}

.npsFinisher {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.npsFinisher > button {
  background-color: #FFFFFF;
  margin-top: 16px;
  width: 30%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 4% 8%;
}

.npsFinisher > button:hover {
  background-color: #FAA123;
  transition: 0.5s;
}

@media (min-width: 1024px) {

  .homeHeader {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }


  .headerSideContent > img {
    max-height: 20px;
    width: auto;
  }

  .homeHeader h2 {
    margin: 0;
    margin-left: 48px;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
  }

  .homeHeader > .headerSideContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 48px;
  }

  .homeHeader > .headerSideContent p {
    margin: 0;
    margin-right: 37px;
    font-size: 14px;
    line-height: 17px;
  }

  .npsDiv {
    right: 150px;
    top: 60px;
    width: 425px;
    padding: 8px;
  }

}