.HomePage {
  display: flex;
  flex-direction: column;
  min-height: 98.4vh;
}

.HomePage h2 {
  font-weight: 600;
}

.greenText {
  color: #009C06;
}

.redText {
  color: #D00101;
}

.galleryText {
  font-weight: 500;
  margin-top: 26px;
}

.genericBtn {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: 0.5s;
  padding: 15px 20px;
  font-size: 14px;
}

.genericBtn > .plus {
  color: #009C06;
  margin-right: 9px;
  font-size: 35px;
  transition: 0.5s;
}

.genericBtn:hover {
  background-color: #FAA123;
}

.twoButtonsDiv {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.marginLeftBtn {
  margin-left: 16px;
}

.genericBtn:hover .plus {
  color: black;
}

.btnWithImage {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.HomeComponent > .homeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeContent > .homeFirstDiv {
  width: 90%;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
}

.homeContent > .homeSecondDiv {
  width: 90%;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
}

.homeContent > div {
  width: 90%;
}

.homeFirstDiv > .clientsDiv {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  padding: 24px 25px;
  border-radius: 8px;
}

.homeFirstDiv > .paymentDiv {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  padding: 24px 25px;
  border-radius: 8px;
}

.standardHomeDiv {
  transition: 0.3s;
  margin-bottom: 40px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  padding: 24px 25px;
  border-radius: 8px;
}

.galleryDiv > h3, .galleryDiv > .progressBarsDiv, .galleryDiv > p, .galleryDiv > button, .galleryDiv > .twoButtonsDiv  {
  margin-left: 24px;
  margin-right: 24px;
}

.galleryDiv > .twoButtonsDiv {
  margin-top: 26px;
  margin-bottom: 32px;
}

.HomeComponent > #homeText {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

.headingText {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.standardText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.galleryBanner {
  width: 100%;
}

.galleryBanner img {
  width: 100%;
  height: auto;
}

.documentationBtn {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 90%;
}

.progressBarsDiv {
  margin-bottom: 33px;
  display: flex;
  flex: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {

  .HomePage {
    display: flex;
    flex-direction: row;
  }

  .homeContent > .homeFirstDiv {
    justify-content: flex-start;
    width: 95%;
    height: 100%;
  }

  .homeContent > .homeSecondDiv {
    padding-left: 40px;
    padding-right: 48px;
    justify-content: flex-start;
    width: 105%;
    height: 100%;
  }

  .homeFirstDiv > .clientsDiv {
    width: 100%;
    padding: 28px 0px 31px 28px;
  }

  .headingText {
    font-size: 24px;
    line-height: 30px;
  }

  .standardText {
    font-size: 20px;
    line-height: 24px;
  }

  .standardHomeDiv {
    padding: 28px 31px;
  }

  .galleryDiv > h3, .galleryDiv > .progressBarsDiv, .galleryDiv > p, .galleryDiv > button  {
    margin-left: 28px;
    margin-right: 28px;
  }

  .HomeComponent > .homeContent {
    align-items: flex-start;
    flex-direction: row;
    margin-top: 36px;
    margin-left: 48px;
  }

  .genericBtn {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 17px 28px;
  }
  
  .btnWithImage {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 17px 28px;
  }
  
  .btnWithImage img{
    margin-right: 9px;
  }

  .HomeComponent > #homeText {
    text-align: start;
    font-size: 20px;
    line-height: 25px;
    margin-left: 48px;
    margin-top: 8px;
  }
  
  .HomePage > .HomeComponent {
    width: 81%;
  }

  .marginLeftBtn {
    margin-left: 32px;
  }

  .twoButtonsDiv {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }
}

.galleryDiv {
  padding-left: 0;
  padding-right: 0;
}