.BlogPost {
  padding: 28px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  border-radius: 8px;
  align-items: center;
  overflow: hidden;
}

.postImage {
  width: 100%;
  max-width: 324px;
  transition: 0.5s;
}

.postImage > img {
  width: 100%;
}

.postHeaders {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.textAndImage {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.textAndImage img {
  margin-right: 12px;
}

.postAndText {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.postAndText p {
  word-wrap: break-word;
  margin-top: 40px;
  margin-bottom: 60px;
  text-align: center;
}

.yellowUnderlineText {
  font-size: 15px;
  line-height: 16px;
  padding-bottom: 5px;
  border-bottom: 2px solid #FAA123;
}

.BlogPost > button {
  max-width: 250px;
  min-width: 180px;
  justify-content: center;
  align-self: center;
}

.multipleBlogTexts {
  width: 100%;
  margin: 0;
}

.postTitleSibebar {
  font-size: 18px;
}

@media (min-width: 1024px) {

  .postImage {
    max-width: 50%;
  }

  .BlogPost {
    align-items: flex-start;
  }

  .postAndText {
    margin-top: 32px;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .postHeaders {
    justify-content: flex-start;
  }

  .textAndImage {
    margin-right: 48px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }

  .postAndText {
    flex-direction: row;
  }

  .postAndText p {
    max-width: 50%;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 24px;
    overflow: hidden;
    text-align: start;
  }

  .expanded > .multipleBlogTexts {
    min-width: 100%;
    margin: 0;
    margin-top: 28px;
  }
}

.BlogPost > .expanded {
  display: flex;
  flex-direction: column;
}