.CriarPost {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goBackText {
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
}

.goBackText:hover {
  opacity: 1;
}

.formHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.formBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.standardInput {
  width: 100%;
  margin-top: 24px;
  padding: 15px 24px;
  border: 2px solid #FAA123;
  border-radius: 8px;
  transition: 0.5s;
}

.standardInput::placeholder {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
}

.standardInput:focus {
  outline: none;
  background-color: #FCD091;
  border: 2px solid #FAA123;
  border-radius: 8px;
}

#imageInput {
  display: none;
}

.fileInput::-webkit-file-upload-button {
  visibility: hidden;
}

.fileInput {
  margin-top: 34px;
  padding: 16px 20px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  outline: none;
  cursor: pointer;
  transition: 0.5s;
  font-size: 18px;
}

.fileInput:hover {
  background-color: #FAA123;
}

.formBody > div {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formBody > div input {
  min-width: 30%;
  max-width: 45%;
}

.formBody > textarea {
  min-height: 250px
}

.formBody > .addMediaDiv {
  justify-content: flex-start;
}

.inputWithBottomBorder {
  border: none;
  padding: 28px 17px;
  border-bottom: 2px solid #FAA123;
}

.inputWithBottomBorder::placeholder {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
}

.inputWithBottomBorder:focus {
  outline: none;
}

.addMediaDiv > .previewImagesDiv {
  display: flex;
  flex-direction: column;
  margin-left: 52px;
}

.addMediaDiv > .previewImagesDiv img {
  cursor: pointer;
  max-height: 75px;
  max-width: 150px;
  object-fit: cover;
  width: auto;
  border: 2px solid #FAA123;
}

.formBody > button {
  justify-content: center;
  width: 30%;
}

.saveText {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 32px;
}

.hidden {
  display: none;
  width: 0;
  height: 0;
  overflow: hidden;
}

@media (max-width: 1023px) {

  .inputWithBottomBorder::placeholder {
    font-size: 12px;
  }
}
