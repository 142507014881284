button {
  background-color: #Faa123;
  border: 0
}

/* @font-face {
  font-family: "Montserrat Medium";
  src: url('./Media/Font/Montserrat-Medium.woff'),
    url('./Media/Font/Montserrat-Medium.woff2');
}

@font-face {
  font-family: "Montserrat Semibold";
  src: url('./Media/Font/Montserrat-SemiBold.woff');
} */

@font-face {
  font-family: "Montserrat Regular";
  src: url('./Media/Font/Montserrat-Regular.woff'),
    url('./Media/Font/Montserrat-Regular.woff2');
}

.Page {
  height: 98.4vh;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat Regular";
  background-color: #FFFFFF;
  line-height: 150%;
  overflow-x: scroll;
}

button {
  font-family: "Montserrat Regular";
  cursor: pointer;
  transition: 0.2s;
}

input {
  font-family: "Montserrat Regular";
}
